<template>
  <div class="container-fuild">
    <div class="row justify-content-center m-0 p-lg-5 p-2">
      <div
        class="col-xl-auto col-lg-auto col-md-7 col-sm-12 col-12 login-card p-4 rounded my-5"
      >
        <center>
          <div class="loader"></div>
        </center>

        <div class="text-center">
          <span class="text-center">
            <i class="bi bi-kanban-fill fs-1 icon"></i>
          </span>
          <br />
          <span class="fs-3">
            PSM - <span class="text-danger fs-3"> Gestion Commerciale </span>
          </span>
        </div>
        <br />

        <div class="fs-5">
          L'application est en cours de chargement, veuillez patienter quelques
          instants.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      auth: {},
      progress: false,
    };
  },
  methods: {
    
  },
  computed: {
    ...mapGetters("auth", {
      error: "getError",
    }),
  },
  async created() {
    try {
      if (this.$route.query.username && this.$route.query.password) {
        const username = this.$route.query.username;
        const password = this.$route.query.password;
        await (this.progress = !this.progress);
        await this.$store.dispatch("auth/login", { username, password });
        setTimeout((this.progress = !this.progress), 6000);
      }
    } catch (e) {
      console.log(e.message);
    }
  },
};
</script>

<style scoped>
.container-fuild {
  min-height: 100vh;
  /* background-color: red; */
  background-color: #689ae6;
  background-image: -webkit-linear-gradient(
    -28deg,
    #d9725f 0%,
    #d9725f 60%,
    #6f73fc 60%,
    #6f73fc 60%
  );
  background-image: linear-gradient(
    -28deg,
    #d9725f 0%,
    #d9725f 60%,
    #6f73fc 60%,
    #6f73fc 60%
  );
}

.login-card {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
@keyframes icon-animation {
  0% {
    color: #689ae6;
  }
  50% {
    color: #b14343;
  }
  100% {
    color: #689ae6;
  }
}
.icon {
  animation-name: icon-animation;
}
.fs-1 {
  font-size: 55px !important;
}
.form-control {
  padding: 0.6rem 0.8rem;
}
.loader {
  display: inline-block;
  width: 70px;
  height: 70px;
  border: 10px solid #689ae6;
  border-top: 10px solid #b14343;
  border-radius: 100%;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
